import React from 'react';

export const Footer = () => {
	return (
		<div className='footer'>
			<div>
				<a href="https://www.buymeacoffee.com/mqLMb2X/extras"
				   className='bottomLinkText '
				   target='_blank'>
					Congratulations,🔥🎂 draw cartoon avatar from your photo😘😘
				</a>

				<div className='signature'>
					<a
						target='_blank'
						rel='noopener noreferrer'
						href='https://cvbox.org/'
						className='boldText removeDefaultAnchorStyle'>
						cvbox
					</a>
				</div>
			</div>

		</div>


	);
};
